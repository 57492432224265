import { useEffect, useState } from 'react'
import { aOrganization, useAtomValue } from '~/atoms'

const searchPlace = async (
  input: string,
  locationRestriction?: google.maps.LatLngBounds
): Promise<google.maps.places.AutocompletePrediction[] | null> => {
  return new Promise((resolve, reject) => {
    const service = new google.maps.places.AutocompleteService()
    service.getPlacePredictions(
      {
        input,
        types: ['geocode'],
        locationRestriction,
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions)
        } else {
          reject(null)
        }
      }
    )
  })
}

const getPlace = (
  placeId: string
): Promise<google.maps.GeocoderResult | null> => {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ placeId }, (results, status) => {
      if (status == 'OK') {
        resolve(results![0])
      } else {
        reject(null)
      }
    })
  })
}

const getAddressValues = (result: google.maps.GeocoderResult) => {
  const addressParts = [
    result.address_components.find((comp) =>
      comp.types.includes('street_number')
    ),
    result.address_components.find((comp) =>
      comp.types.includes('intersection')
    ),
    result.address_components.find((comp) => comp.types.includes('route')),
  ]
    .filter((p) => p)
    .map((p) => p!.short_name)
  const city = result.address_components.find(
    (comp) =>
      comp.types.includes('locality') ||
      comp.types.includes('administrative_area_level_3')
  )
  const state = result.address_components.find((comp) =>
    comp.types.includes('administrative_area_level_1')
  )
  const postal = result.address_components.find((comp) =>
    comp.types.includes('postal_code')
  )
  const county = result.address_components.find((comp) =>
    comp.types.includes('administrative_area_level_2')
  )
  const values: {
    // [key: string]: string
    address: string
    city: string
    stateAbbr: string
    postalCode: string
    county: string
  } = {
    address: addressParts.length > 0 ? addressParts.join(' ') : '',
    city: city?.long_name || '',
    stateAbbr: state?.short_name || '',
    postalCode: postal?.long_name || '',
    county: county?.long_name || '',
  }
  return values
}

export default function useGeocodeSearch() {
  const organization = useAtomValue(aOrganization)
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])

  let debounceTimeout: any = null
  const debounceSearch = (input: string) => {
    if (search.length === 0) {
      setPredictions([])
    } else {
      if (debounceTimeout !== null) {
        clearTimeout(debounceTimeout)
      }
      setIsLoading(true)
      debounceTimeout = setTimeout(async () => {
        try {
          const placeSearchBounds = organization?.options.placeSearchBounds
          const bounds = !placeSearchBounds
            ? undefined
            : new google.maps.LatLngBounds(
                new google.maps.LatLng(
                  placeSearchBounds[0][0],
                  placeSearchBounds[0][1]
                ),
                new google.maps.LatLng(
                  placeSearchBounds[1][0],
                  placeSearchBounds[1][1]
                )
              )

          const predictions = await searchPlace(input, bounds)
          setPredictions(predictions || [])
          setIsLoading(false)
        } catch (_) {}
      }, 200)
    }
  }

  const getPlaceData = async (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    const place = await getPlace(prediction.place_id)
    if (!place) {
      return null
    }
    const zoom = place!.types.includes('political') ? 9 : 14
    const lng = place!.geometry.location.lng()
    const lat = place!.geometry.location.lat()
    return {
      zoom,
      lng,
      lat,
      ...getAddressValues(place),
    }
  }

  useEffect(() => {
    debounceSearch(search)
  }, [search])

  return {
    search,
    setSearch,
    isLoading,
    predictions,
    getPlaceData,
  }
}
